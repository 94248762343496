import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "en-digital-assistants",
  "title": "What is a digital assistant?",
  "text": "A digital assistant is a software robot that can perform a wide range of tasks that humans would otherwise be doing. It imitates our way of working using the same interfaces and systems but significantly faster.",
  "mascot": "/img/robbie.png",
  "buttonText": "Create your assistant",
  "buttonPath": "createassistant",
  "categories": [{
    "bg": "#ffffff",
    "caseTitle": "Case: Acne Studios",
    "textList": [{
      "text": "Many processes at an accounting department have repetitive and mundane elements. The accounting department is therefore highly suitable for automation. Common processes for automation are reconciliations of accounts, billing, handling incoming and outgoing invoices and payroll administration."
    }],
    "checkpoints": [{
      "text": "Account reconciliations"
    }, {
      "text": "Invoicing"
    }, {
      "text": "Invoice administration"
    }, {
      "text": "Accounts payable"
    }, {
      "text": "Accounts receivable"
    }, {
      "text": "Financial reporting"
    }, {
      "text": "Bookkeeping"
    }],
    "text": "test",
    "optionalButton": {
      "text": "-",
      "path": "-"
    },
    "caseText2": "test",
    "title": "Accounting",
    "image": "/img/calc.png",
    "caseTextList": [{
      "text": "The accounts payable department receives invoices from suppliers that need to be verified and approved on a daily basis. The approval is given by the respective department before staff at the accounts payable department manually verifies and gives a final approval before the payment is settled to the supplier. The process is highly manual, mundane and time consuming."
    }, {
      "text": "Zimply has helped Acne Studios to automate the invoice approval process using a robot that examines each invoice and verifies it against predefined rules (verifying the correct account, currency, company etc). If everything is in order, the robot approves the invoice for payment to the supplier. In case of discrepancies, the robot returns the invoice to the responsible purchaser."
    }],
    "caseText": "test"
  }, {
    "title": "Backoffice",
    "textList": [{
      "text": "Increasing financial regulations means the amount of compliance related administrative processes that back office departments need to handle are steadily growing. The fact that the supporting IT systems are not growing at the same pace for many organizations often results in manual and tedious tasks taking up their employees’ valuable time. Manual work also means increased operative risks of human errors."
    }, {
      "text": "Meanwhile, digitalization of many work processes means that data such as invoices, documents and contract agreements need to be digitized from physical documents and entered into business systems."
    }, {
      "text": "Thanks to short development time and high adaptability the use cases of digital assistants are plentiful and the business value is high with a typical ROI of around three months."
    }],
    "caseTitle": "Case: Futur Pension",
    "caseTextList": [{
      "text": "Futur Pension had a large amount of unstructured documents of insurance applications dating several years back from which the information needed to be extracted and entered into their ERP-system."
    }, {
      "text": "Zimply automated the text extraction and interpretation for Futur Pension by training a digital assistant to automatically read and process the documents. The technology used is called Computer Vision. In total 22 000 documents were scanned and extracted in less than one month, start to finish."
    }],
    "image": "/img/paper.png",
    "bg": "#f1f5fe",
    "checkpoints": [{
      "text": "Claims registration"
    }, {
      "text": "Open/close accounts or credit cards"
    }, {
      "text": "Compliance processes"
    }, {
      "text": "AML/KYC"
    }, {
      "text": "Duality checks"
    }],
    "optionalButton": {
      "text": "-",
      "path": "-"
    }
  }, {
    "title": "HR",
    "textList": [{
      "text": "Employer branding is more important than ever due to a more mobile workforce and harder competition for top talents. By automating the administrative parts of HR processes, time is freed up from your HR-staff meaning they can invest it in more value adding tasks such as focusing on becoming a more attractive employer with happy and healthy employees. Let Zimply help you create the optimal conditions for your HR department to thrive by automating and freeing them from administrative tasks."
    }],
    "caseTitle": "Case: (consulting firm)",
    "caseTextList": [{
      "text": "Zimply helped the consulting firm to streamline their manual onboarding process with a digital assistant to which their HR staff could send an email with new employment agreements. The robot opens the document and extracts the contact and employment details, logs in to the internal HR system and creates a new user for the employee with the extracted details. A welcome letter is finally sent to the employee."
    }],
    "image": "/img/thinking.png",
    "bg": "#c7d8e6",
    "checkpoints": [{
      "text": "CV screening"
    }, {
      "text": "On- and offboarding of employees"
    }, {
      "text": "Expenses administration"
    }, {
      "text": "Time reports administration and follow-up"
    }, {
      "text": "Absence management"
    }, {
      "text": "Payroll administration"
    }, {
      "text": "Reference collection and administration"
    }],
    "optionalButton": {
      "text": "-",
      "path": "-"
    }
  }, {
    "title": "IT",
    "textList": [{
      "text": "Many of the tasks within IT support are conducted on a daily basis with repetitive steps spanning multiple systems. The handling time should also be minimal so support tickets get solved as soon as possible in order to reduce lead time for users."
    }, {
      "text": "Let your IT department focus on your digital transformation journey and develop your systems while you get more effective and efficient IT support with shorter lead times by using digital assistants. It will be appreciated not only by your IT staff, but also by your business users and customers."
    }],
    "caseTitle": "Case: Insurance company",
    "caseTextList": [{
      "text": "Our digital assistant is used for test automation for the IT department of a large insurance company. The customer has recurring releases of system updates that need to be tested. This used to be done manually by IT and business staff. It was a very time consuming and repetitive task requiring plenty of patience and accuracy. Zimply’s digital assistants perform regression testing, system testing and acceptance testing. They work in multiple systems depending on what is being tested. The digital assistants minimise the risk of human errors, perform the testing faster and at a fraction of the cost."
    }],
    "image": "/img/laptop.png",
    "bg": "#ffffff",
    "checkpoints": [{
      "text": "User access administration"
    }, {
      "text": "Data migration"
    }, {
      "text": "On- and offboarding of employees’ user accesses"
    }, {
      "text": "Infrastructure administration (e.g. Azure, AWS, VMWare, and Citrix environments)"
    }, {
      "text": "Files handling"
    }, {
      "text": "Test automation"
    }],
    "optionalButton": {
      "text": "-",
      "path": "-"
    }
  }, {
    "title": "Procurement/Retail",
    "textList": [{
      "text": "Ongoing order handling and delivery planning along with contracts administration and registrations are common time consumers, stealing valuable time. These monotonous tasks are excellent candidates for automation using a digital assistant."
    }],
    "caseTitle": "Case: Acne Studios",
    "caseTextList": [{
      "text": "The purchasing department at Acne Studios regularly updates the estimated delivery date of purchase orders. The staff updates the date in the business system based on an Excel file that is sent to the department regularly. Each file can contain hundreds of rows with different articles that need to be manually updated."
    }, {
      "text": "Zimply automated the updating of purchase orders using a digital assistant that reads the input file and then updates the delivery date for each order, saving time for Acne Studios."
    }],
    "checkpoints": [{
      "text": "Purchase order handling/registration"
    }, {
      "text": "Contract management"
    }, {
      "text": "Delivery planning"
    }, {
      "text": "Contract registration"
    }, {
      "text": "Purchase order handling/registration"
    }, {
      "text": "Store reconciliation"
    }],
    "bg": "#f7ffeb",
    "image": "/img/order.png",
    "optionalButton": {
      "text": "-",
      "path": "-"
    }
  }, {
    "title": "Others",
    "textList": [{
      "text": "Each company is unique with its own variety of processes and work tasks. There are also processes spanning multiple departments, such as when a salesperson registers a new client and the administrative department continues to register the client’s info in the CRM system."
    }],
    "caseTitle": "-",
    "caseTextList": [{
      "text": "Zimply automates all types of processes for companies within all industries. Book a demo today to find out how we can help you and your business."
    }],
    "image": "/img/glass.png",
    "bg": "#f1f5fe",
    "checkpoints": [],
    "optionalButton": {
      "text": "Create your assistant",
      "path": "createassistant"
    }
  }],
  "bottom": {
    "bg": "/img/pink.png",
    "title": "Get started!",
    "text": "Once the processes to automate have been selected, the adaptation work begins. Join us in a world of new technical possibilities, tailored to your needs. Contact us below!",
    "buttonText": "Contact us",
    "buttonPath": "contact"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      